<template>
  <div>
    <div class="mainpage-header">
      <div style="vertical-align: baseline; float: left">
        <router-link to="/">
          <img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png">
        </router-link>
      </div>
      <div style="float: right;" @click="">
        <div v-if="!openCloseLoginForm" @click="openCloseLoginForm = true">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_black.svg"/>
        </div>
        <div v-if="openCloseLoginForm" @click="openCloseLoginForm = false">
          <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_menu_close_black.svg"/>
        </div>
      </div>
    </div>

    <div v-if="!openCloseLoginForm">
    <div style="clear: both;"></div>
    <div class="mainpage-body">
      <h1 class="homeH1">
        Yetenekli freelancerlarla<br><span>daha çok iş yapın.</span>
      </h1>

      <div class="get-started-body">

        <button class="super-button get-started-button" @click="openCloseLoginForm = !openCloseLoginForm">Hadi Başla</button>
      </div>

      <div class="mainpage-illustration">
        <h1>Neden <br><span style="font-weight: 600;">Freelancer?</span></h1>
        <div class="illustration-body">
          <div class="illustration-inner">
            <img style="height: 160px; width: 160px;" src="https://gcdn.bionluk.com/site/general/search_bi.png"/>
            <p class="illustration-main-paragraph">Sınırsızlık, Özgürleştirir</p>
            <p class="illustration-paragraph">
              Sınırsız düşünebilmen için bütün sınırları kaldırdık. Artık yalnızca bulunduğun bölgeden değil dünyanın her yerinden global yeteneklerle, özgürce çalışabilmen için...
            </p>
          </div>

          <div class="illustration-inner">
            <img style="height: 160px; width: 160px;" src="https://gcdn.bionluk.com/site/general/contact-order_bi.png"/>
            <p class="illustration-main-paragraph">Bakış Açını Genişlet</p>
            <p class="illustration-paragraph">
              El elden, göz gözden üstündür. Hedef kitleni ne kadar genişletmek istersen, o kadar farklı gözle bakmayı öğrenirsin. Biz senin için her pencereyi açtık, bakmak sana kaldı.
            </p>
          </div>
          <div class="illustration-inner">
            <img style="height: 160px; width: 160px;" src="https://gcdn.bionluk.com/site/general/done_bi.png"/>
            <p class="illustration-main-paragraph">Aklın Yolu Free</p>
            <p class="illustration-paragraph">
              Becerikli bir işveren, kaynaklarını en verimli nasıl kullanacağını bilen iş verendir. Kaynaklarını olabildiğince verimli kullanmak adına freelancer’lara şans verin; göreceksin ki her akıl, her işe değer katar.
            </p>
          </div>
        </div>

        <div class="mainpage-learnmore-div">
          <router-link style="text-decoration: none" to="/register">Hemen Kaydol</router-link>
        </div>
      </div>
    </div>


      <div class="mainpage-marketplace-body">
        <h1>
          <p style="font-weight: 600;">Bi'<span style="font-weight: bold; color: #2D3640">Blog</span></p>
        </h1>
        <p style="margin-top: 5px; font-size: 18px; font-weight: 300; color: #8b95a1; text-align: center;">Freelancerlar ve girişimciler için özgür çalışma blogu</p>

        <div class="marketplace-gigs-container">


          <div   style="">


            <div style="display: flex; flex-direction: column; align-items: center; ">
              <router-link :to="'/blog/'+block.slug"
                           v-for="(block, index) in blogLists"
                           :key="index"
                           class="nounderlinehover"
                          style="width: 100%; display: block">
                <div style="margin: 20px auto 20px; width: 90%; text-align: center; display: flex; align-self: center; align-items: center;">
                  <div v-if="block.style_type !== 'list'" :class="['mobileCard', 'generalCard', 'card_'+block.style_type, block.color]" :style="'width: '+screenWidth+'px; height: 300px; background-size: cover; background-image: url('+block.image_url_list+');'">
                    <div v-if="block.style_type === 'bottom_title' || block.style_type === 'long_title'" class="bottom_text_placeholder">
                      <p class="subheader">{{block.subheader}}</p>
                      <p class="title" v-html="block.header" :style="'backgroundColor:'+computedBG(block.header_bg_color)"></p>
                      <p class="subheader2">{{block.subheader2}}</p>
                    </div>
                    <div v-else>
                      <p class="subheader">{{block.subheader}}</p>
                      <p class="title" v-html="block.header"></p>
                      <p class="subheader2">{{block.subheader2}}</p>
                    </div>
                  </div>
                  <div v-if="block.style_type === 'list'" :class="['mobileCard', 'generalCard', 'card_'+block.style_type, block.color]" :style="'width: '+screenWidth+'px; padding-bottom:20px'">
                    <p class="subheader">{{block.subheader}}</p>
                    <p class="title" v-html="block.header"></p>
                    <p class="subheader2">{{block.subheader2}}</p>

                    <div class="hiddendiv10"></div>
                    <div class="freelancer-list">
                      <div v-for="(list , indexx) in block.renderedLists" :key="indexx" v-if="indexx < 4" class="freelancer-list-container">
                        <div class="freelancer-list-div">
                          <div class="freelancer-list-div-div">
                            <img class="freelancer-list-avatar" :src="list.image_url"/>
                          </div>
                          <div class="freelancer-list-second-flex">
                            <p v-if="list.type != 'gig'" class="freelancer-list-username_s">{{list.title}}</p>
                            <p v-if="list.type != 'gig'" class="freelancer-list-user-title">{{list.subtitle}}</p>
                            <p v-if="list.type == 'gig'" class="freelancer-list-username_s">{{list.subtitle}}</p>
                          </div>
                        </div>
                        <div v-if="index < 3" class="freelancer-list-border_s" ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>


          </div>


        </div>

        <div class="mainpage-learnmore-div" style="">
          <router-link style="text-decoration: none;" to="/blog">Tüm Yazılar</router-link>
        </div>
        <div class="hiddendiv>"></div>
        <div class="hiddendiv>"></div>

      </div>


    <div class="mainpage-marketplace-body" style="background-color: white">
      <h1>
        Bionluk <br><span style="font-weight: 600;">Freelancerları</span>
      </h1>
      <p style="margin-top: 5px; margin-bottom: 30px; line-height: 23px; font-size: 18px; font-weight: 300; color: #8b95a1; text-align: center;">Kendi alanlarında uzman, özenle seçilmiş freelancerlarımızla tanışın.</p>

      <div class="marketplace-gigs-container">
        <div class="marketplace-gigs-body" v-for="(gig, gindex) in gigs" :key="gindex" style="margin-bottom: 50px;">
          <singleGig :impression-u-u-i-d="impressionUUID" :clicked-from="'default'" :item="gig"  />
        </div>
      </div>

      <div class="marketplace-bottom-div">
        <router-link to="/freelancer-bul">
          <button class="super-button marketplace-button">Daha Fazlası</button>
        </router-link>
      </div>

    </div>


    <div class="mainpage-bottom mainpage-freelancers" >

      <h1>
        <span>Geleceğin "çalışma" modeliyle</span> bugünden <span>tanışın.</span>
      </h1>
      <div class="mainpage-bottom-button-div">
        <router-link to="/register">
          <button class="mainpage-bottom-button">Hemen Başla</button>
        </router-link>
      </div>
    </div>
    </div>
      <div v-if="openCloseLoginForm" class="mainpage-mobile-login-form">

      <button class="super-button-light login-form-button-explore"
              @click="$router.push('/freelancer-bul')">Bionluk'u Keşfet</button>
        <div style="width: 120px; height: 2px;border-bottom: solid 1px #dde3eb; margin-top: 31px; margin-bottom: 29px;"></div>
      <button class="super-button-light login-form-button-freelancer"
              @click="$router.push('/freelancer-olmak')">Freelancer Ol</button>
      <button class="super-button login-form-button-login"
              @click="$router.push('/login')">Giriş / Kaydol</button>
        <div style="width: 271px; height: 3px; border-bottom: solid 1px #dde3eb; margin-top: 85px;"></div>

        <div>
        <div style="margin-top: 20px; margin-bottom: 20px;display: flex;align-items: center">
          <div style="cursor: pointer;">
            <a href="https://facebook.com/bionluk" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_facebook_gri.svg"/>
            </a>
          </div>

          <div style="cursor: pointer; margin-left: 30px;;">
            <a href="https://twitter.com/bionluk" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_twitter_gri.svg"/>
            </a>
          </div>

          <div style="cursor: pointer; margin-left: 30px;">
            <a href="https://instagram.com/bionlukcom" target="_blank">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_mobile_instagram_gri.svg"/>
            </a>
          </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>

import singleGig from "@/pages/components/singleGig";
  export default {
    components:{singleGig},
    name: "src-pages-body-main-v3_mobile",
    data() {
      return {

        impressionUUID:null,
        slug: null,
        deliveryTime: null,
        price: null,
        checkedEmail: null,
        gigs: [],

        freelancers: null,

        openCloseLoginForm: false,

        blogLists: [
          {
            title:null,
            subtitle:null,
            data: [
              {
                "header": "",
                "subheader": "",
                "subheader2": null,
                "style_type": "",
                "size_type": "A2",
                "slug": "",
                "color": "",
                "renderedLists": [],
                "image_url_list": "https://gcdn.bionluk.com/site/general/blank_gig.png",
                "description": null,
                "footer_div":{},
                "htmlList":[]
              }
            ]
          }
        ],
        screenWidth: (window.innerWidth * 0.9),
        screenHeight: (window.innerWidth * 1.23),
      }
    },

    methods: {

      computedBG(hex){
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ?
          'rgba('+ parseInt(result[1], 16) + ','+ parseInt(result[2], 16) + ',' +  parseInt(result[3], 16) + ',0.5)'
          : null;
      },

      getBlogListv4() {
        this.api.content.getBlogListv4(0, 2)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.blogLists = result.data.blog_list;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err});
          });
      },

      checkEmailIsUserRegistered() {
        if (!this.checkedEmail) {
          this.$toasted.global.errorToast({description: "Enter Your Email"});
        } else {
          this.api.general.checkEmailIfUserRegisteredOrNot(this.checkedEmail, this.$Progress)
            .then(({data}) => {
              let result = data;

              this.$router.push(result.data.redirect_url);
              this.$store.state.user.email = this.checkedEmail;
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            })
        }
      },

      getGigsMainpage() {
        this.api.general.getGigsMainpage()
          .then(({data}) => {
            let result = data;
            if (result.success) {
              let trackGigObj = [];
              result.data.gigs.forEach((gig, gigdex) => {
                trackGigObj.push({
                  gig_id:gig.id,
                  seller_id:gig.seller_id,
                  order_id: gigdex + 1
                })
              });
              let trackingProps = {
                page:'homepage',
                placement:'default',
                info:trackGigObj,
                title: this.impressionUUID
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              this.gigs = result.data.gigs;
            }
          });
      },

    },

    created() {

      this.impressionUUID = this.Helper.generateUUID();

      this.getGigsMainpage();
      this.getBlogListv4();
    }
  }
</script>

<style scoped>
  .mainpage-header {
    padding-top: 20px;
    padding-right: 15px;
    padding-left: 13px;
  }

  /*body*/
  .mainpage-body {
    padding-top: 84px;

  }

  .homeH1 {
    font-size: 32px;
    font-weight: 300;
    line-height: 1.25;
    text-align: center;
    color: #2d3640;
    margin:0;
  }

  .homeH1 span {
    font-weight: 500;
  }

  /*get started*/
  .get-started-body {
    transition: .5s;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 20px 20px 0 rgba(125, 134, 151, 0.24);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 15px;
    margin-top: 30px;
  }

  .get-started-input {
    width: 246px;
    height: 60px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    font-size: 18px;
    text-align: left;
    color: #a1a9a9;
    padding: 0;
    padding-left: 21px;
    padding-right: 21px;
  }

  .get-started-input::placeholder {
    font-size: 16px;
    text-align: left;
    color: #a1a9a9;
  }

  .get-started-button {
    width: 290px;
    height: 60px;
    border-radius: 2px;
    background-image: linear-gradient(45deg, rgb(230, 50, 98), rgb(253, 64, 86));
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-top: 6px;
  }

  /*illustration*/
  .mainpage-illustration {
    padding-bottom: 39px;
    padding-top: 38px;
  }

  .mainpage-illustration h1 {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.29;
    text-align: center;
    color: #2d3640;
    margin: 0;
  }

  .illustration-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -25px;
  }

  .illustration-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
  }

  .illustration-main-paragraph {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 4px;
  }

  .illustration-paragraph {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.44;
    text-align: center;
    color: #8b95a1;
    word-break: break-word;
    width: 290px;
    margin-top: 22px;
  }

  .mainpage-learnmore-div {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    margin: auto;
    margin-top: 35px;
  }

  .mainpage-learnmore-div a {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
  }

  /*marketplace*/
  .mainpage-marketplace-body {
    background-color: #f4f5f7;
    padding-top: 42px;
    padding-bottom: 28px;
  }

  .mainpage-marketplace-body h1 {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    text-align: center;
    color: #2d3640;
    margin: 0;
  }

  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_left.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    left: 0;
    opacity: 1;
    border-left: none;
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_right.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    right: 0;
    opacity: 1;
    border-right: none;
  }

  .swiper-button-disabled {
    opacity: 0.7;
    pointer-events: all !important;
  }

  .marketplace-gigs-container {
    margin: 0 auto;
    text-align: center;
  }

  .marketplace-gigs-body {
    display: inline-block;
    margin-bottom: 21px;
    width: 263px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
    text-align: left;
  }

  .marketplace-gigs-userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }

  .marketplace-bottom-div {
    text-align: center;
  }

  .marketplace-button {
    width: 220px;
    height: 60px;
    background-color: #FFF;
    font-size: 16px;
    font-weight: 600;
    margin-left: 0;
    margin-top: 20px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82,0.2);
    color: rgb(45, 54, 64);
  }

  .marketplace-bottom-paragraph {
    margin-top: 30px;
    font-weight: normal;
    color: #8b95a1;
  }

  .marketplace-bottom-paragraph span {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
  }

  /*meet friends*/

  .mainpage-meetfriends {
    background-color: #f4f5f7;
    padding-top: 40px;
    padding-bottom: 80px;
    text-align: center;
  }

  .mainpage-meetfriends h1 {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
    margin: 0;
  }

  .mainpage-meetfriends h1 span {
    font-weight: 600;
  }

  .thirdone {
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
  }

  /*.thirdone::after {*/
    /*content: "";*/
    /*width: 286px;*/
    /*height: 10px;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*left: 0;*/
    /*margin: auto;*/
    /*position: absolute;*/
    /*opacity: 0.2;*/
    /*background-color: #ffffff;*/

    /*box-shadow: 0 20px 30px 0 #000000;*/
  /*}*/

  .slide-inner {
    margin: 50px 5vw 39px 5vw;
    width: 80vw;
    min-height: 100%;
    background-color: #ffffff;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .slide-inner-img {
    height: 100px;
    width: 100px;
    margin-top: -50px;
    border-radius: 50%;
  }

  .slide-inner-username {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #4b4f52;
    margin-top: 20px;
  }

  .slide-inner-title {
    font-size: 16px;
    text-align: center;
    color: #a1a9a9;
    margin-top: 10px;
  }

  .slide-inner-description {
    font-size: 16px;
    font-style: italic;
    line-height: 1.5;
    text-align: center;
    color: #a5acac;
    word-break: break-word;
    margin-top: 20px;

  }

  /*best freelancers*/

  .mainpage-freelancers {
    padding-top: 42px;
    padding-bottom: 50px;
    background-image: linear-gradient(204deg, #46c5e5, #95e8e9);

  }

  .mainpage-freelancers h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.14;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }

  .mainpage-freelancers h1 span {
    font-weight: 300;
  }

  .mainpage-freelancers-inner {
    margin: 0 auto;
    text-align: center;
    width: 1000px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .skills-tag {
    height: 12px;
    min-width: 20px;
    border-radius: 100px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    font-size: 12px;
    color: #8b95a1;
    display: inline-block;
    position: relative;
    float: left;
    margin-right: 5px;
    margin-bottom: 9px;
    padding: 8px 6px 8px;
  }

  .skill-span {
    height: 12px;
    min-width: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: solid 1px #2d3640;
    font-size: 12px;
    color: #2d3640;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    margin-bottom: 9px;
    padding: 8px 6px 8px;
  }

  .freelancer-div {
    margin: 20px 15vw 0 15vw;
    width: 60vw;
    height: 100%;
    background-color: #ffffff;
    padding-left: 5vw;
    padding-right: 5vw;
    position: relative;
    text-align: center;
  }

  .freelancer-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-top: 49px;
  }

  .freelancer-username {
    font-size: 16px;
    font-weight: 600;
    color: #242424;
    margin-top: 23px;
  }

  .freelancer-title {
    height: 40px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    font-style: italic;
    text-align: center;
    color: #8b95a1;
    margin-top: 5px;
  }

  .freelancer-connect-button {
    width: 159px;
    height: 44px;
    border-radius: 2px;
    background-color: #2d3640;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

  .freelancer-find-button {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    background-color: #ffffff;
    margin: auto;
    margin-top: 41px;
    cursor: pointer;
  }

  .freelancer-find-button p{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    line-height: 50px;
  }


  /*mainpage bottom*/
  .mainpage-bottom {
    background-image: linear-gradient(79deg, rgba(230,50,98, 0.9), rgba(253,64,86, 0.9));
    background-size: contain;
    position: relative;
    height: 158px;
    padding-top: 83px;
    padding-bottom: 87px;
  }

  .mainpage-bottom h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.14;
    color: #ffffff;
    text-align: center;
    margin: 0;
  }

  .mainpage-bottom-button-div {
    margin: auto;
    position: relative;
    width: 290px;
    height: 60px;
    margin-top: 26px;
  }

  .mainpage-bottom-button {
    width: 290px;
    height: 60px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    color: #2d3640;
    border: none;
  }

  .mainpage-bottom-button:hover {
    background-color: #f6f6f6 !important;
  }

  .bottom-right {
    position: absolute;
    display: flex;
    align-items: center;
    top: 30px;
    right: -215px;
  }

  /*search part*/
  .search-container {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
  }

  .search-result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    height: 30px;
    max-width: 451px;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.29;
    text-align: left;
    color: #2d3640;
  }

  .search-result-category-container {
    max-width: 442px;
    padding: 15px 19px 5px 19px;
    background-color: #ffffff;
  }

  .light-button-small {
    width: 170px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;

  }

  .mainpage-mobile-login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100vw; height: 90vh
  }

  .login-form-button-explore {
    width: 270px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .login-form-button-freelancer{
    width: 270px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }
  .login-form-button-login{
    width: 270px;
    height: 50px;
    border-radius: 2px;
    background-color: #2d3640;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }

  .app-logo{
    height: 28px;
    margin-top: 2px;
  }



  .generalCard{
    text-align: left;
    position: relative;
    cursor: pointer;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
  }

  .generalCard:hover{

    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.2);
    text-decoration: none!important;
  }


  .mobileCard{
    background-position: center;
    width: 120%;
  }









  .freelancer-list{
    margin-left: 18px; margin-right: 18px
  }

  .freelancer-list-div{
    display: flex; height: 66px;
  }

  .freelancer-list-div-div{
    align-self: center;
  }

  .freelancer-list-avatar{
    height:48px;border:1px solid #dde3eb;border-radius: 10px;
  }


  .freelancer-list-second-flex{
    margin-left: 15px; height: 48px; align-self: center
  }

  .freelancer-list-username{
    line-height:20px;font-size: 15px; font-weight: 400; color: rgb(36, 36, 36); opacity: 0.6;
  }

  .freelancer-list-username_s{
    line-height:23px;font-size: 17px; font-weight: 500; color: rgb(36, 36, 36);
  }

  .freelancer-list-user-title{
    line-height:16px; font-size: 13px; font-style: italic; color: rgb(139, 149, 161);
  }

  .freelancer-list-border{
    margin-left:100px;border-bottom: 1px solid #e1e1e1;
  }

  .freelancer-list-border_s{
    margin-left:60px;border-bottom: 1px solid #e1e1e1;
  }

  .card_bold .title{

    font-size: 32px;
    bottom: 56px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;


  }


  .card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:17px;line-height: 1.56;
    position: absolute;
    bottom: 32px;
    left: 30px;




  }


  .card_up_title .title{


    font-size: 23px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;


  }


  .card_up_title .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }



  .card_up_title .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }


  .card_list {
    width: 100%;
  }

  .card_list .title{


    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;



  }


  .card_list .subheader{



    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 15px; opacity:0.75;font-weight: 500;
    line-height: 18px;

  }



  .card_list .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }


  .bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 12px;
    right: 12px;
  }


  .card_bottom_title .subheader{


    z-index: 1;

    font-size: 16px; opacity:0.75;font-weight: 500;
    line-height: 19px;

  }


  .card_bottom_title .title{

    font-size: 23px;
    font-weight: 600;
    line-height: 29px;

  }




  .card_long_title .title{
    padding: 6px 10px 8px;
    border-radius: 8px;

    bottom: -4px;
    text-align: left;
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;

  }



  .card_bottom_title .subheader2{

    padding-top: 16px;
    font-size: 15px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity:0.6;

  }




  .white{
    color: #fff;
  }

  .black{
    color: #242424;
  }



  .card_date_line{
    margin-top:40px; margin-left: 20px; height: 20px;
  }

  .card_date_line_left{
    color:#2d3640; font-size: 24px; font-weight: 600;

  }

  .card_date_line_right{
    font-size: 15px; font-weight: 500;color: #8b95a1;
  }

</style>

